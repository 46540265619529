/* eslint-disable import/no-unresolved */
<template>
  <section id="lives">
    <div>
      <add-new
        :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
        @refetch-data="refetchData"
        @close="isAddNewUserSidebarActive = false"
      />

      <edit
        v-if="isEditLiveSidebarActive"
        :is-edit-live-sidebar-active.sync="isEditLiveSidebarActive"
        :data="rowData"
        @refetch-data="refetchData"
        @close="isEditLiveSidebarActive = false"
      />
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrar</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>Lives</label>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="9"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="primary"
                  @click="isAddNewSidebarActive = true"
                >
                  <span class="text-nowrap">Adicionar</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div>
          <div class="overflow-auto">
            <b-table
              id="my-table"
              ref="refUserListTable"
              class="position-relative"
              :items="lives"
              :fields="tableColumns"
              :current-page="currentPage"
              :per-page="perPage"
              responsive
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
            >
              <!-- Column: Actions -->
              <template #cell(card)="data">
                <img :src="getCardUrl(data.item.img)" style="max-height: 65px; min-height: 30px; max-width: 140px;">
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item @click="edit(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="deleteLive(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="speakers(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Palestrantes</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="viewData(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Dados da live</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">A partir de {{ dataMeta.from }} para {{ dataMeta.to }} de {{ dataMeta.of }}</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import useList from './useList'
import userStoreModule from './userStoreModule'
import AddNew from './AddNew.vue'
import Edit from './Edit.vue'
import { mapActions } from 'vuex'
import LivesServices from './LivesServices'
// eslint-disable-next-line import/no-unresolved
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  components: {
    AddNew,
    Edit,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const isAddNewSidebarActive = ref(false)
    const isEditLiveSidebarActive = ref(false)

    const {
      fetchUsers,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {

      // Sidebar
      isAddNewSidebarActive,
      isEditLiveSidebarActive,

      fetchUsers,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  data() {
    return {
      imagePath: require("@/assets/default/cards/livev2.png"), // Use require para importar a imagem
      rowData: {},
      lives: [],
      perPage: 10,
      currentPage: 1,
      nextPage: this.currentPage + 1,
      prevPage: this.currentPage - 1,
      sortDesc: true,
      ready: false,
      perPageOptions: [10, 25, 50, 100, 200, 300, 400, 500],
      selectValue: '',
      filter: [],
      form: [],
      searchValue: null,
      tableColumns: [
        {
          key: 'card',
          label: 'CARD',
          sortable: true,
        },
        {
          key: 'title',
          label: 'Titulo',
          sortable: true,
        },
        {
          key: 'crm_required',
          label: 'CRM Obrigatorio',
          sortable: true,
        },
        {
          key: 'date',
          label: 'Data',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'Ações',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    rows() {
      return this.lives.length
    },
  },
  mounted() {
    LivesServices().getLives().then(api => {
      this.lives = api.data.response.lives
      this.lives.forEach((live, index) => {
        if (live.crm_required === 0) {
          live.crm_required = 'Sim'
        } else {
          live.crm_required = 'Não'
        }
        if (live.ready === 0) {
          live.ready = 'Sim'
        } else {
          live.ready = 'Não'
        }
      })
    })
  },
  methods: {
    getCardUrl(cardImage) {
      const imagePath = cardImage
        ? `${process.env.VUE_APP_API_BASE}/storage/live/image/${cardImage}`
        : this.imagePath;

      return imagePath;
      },
    async deleteLive(data) {
      console.log(data)
      try {
        const response = await LivesServices().liveDelete(data)

        if (response.data.status === 400) {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'InfoIcon',
              title: response.data.message,
              text: ` ERROR: ${response.data.errors[0][0]} #${response.data.errors[0][1]}`,
              variant: 'danger',
            }
          })
        } else if (response.data.status === 200) {
          LivesServices().getLives().then(api => {
            this.lives = api.data.response.lives
            this.lives.forEach((live, index) => {
              if (live.crm_required === 0) {
                live.crm_required = 'Sim'
              } else {
                live.crm_required = 'Não'
              }
              if (live.ready === 0) {
                live.ready = 'Sim'
              } else {
                live.ready = 'Não'
              }
            })
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'InfoIcon',
              variant: 'success',
            }
          })
          this.$emit('close')
          document.location.reload(true)
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'InfoIcon',
            title: err.message,
            variant: 'danger',
          }
        })
      }
      this.liveDelete({
        id: data.id
      })
    },
    edit(data) {
      this.rowData.id = data.id
      this.rowData.title = data.title
      this.rowData.url = data.url
      this.rowData.img = data.img
      this.rowData.crm_required = data.crm_required
      this.rowData.ready = data.ready
      this.rowData.date = data.date
      this.rowData.time = data.time
      this.rowData.type = data.type
      this.isEditLiveSidebarActive = true
    },
    speakers(data) {
      window.location.href = `/administrador/live/${data.slug}/${data.id}/palestrantes?live=/${data.title}`
    },
    viewData(data) {
      window.location.href = `/live/info/${data.slug}/${data.id}`
    }
  }
}
</script>

<style lang="scss">
</style>
